import {
  BehaviorSubjectSubscribe,
  BehaviorSubject,
  createBehaviorSubject
} from 'packages/behavior-subject';
import { Fetcher, RequestConfig } from 'packages/http-client/fetcher';
import {
  AuthorizationBlocks,
  Brand,
  ProgramType
} from 'apps/acp/packages/webapi';
import { Bank } from 'apps/acp/packages/oltp-types';
import { isLimitedUseAccessToken } from 'apps/acp/packages/limited-use-access-token';

export interface BrandingFetcherInterface {
  brand: Brand;
  bank?: Bank;
  association?: string;
  type?: ProgramType;
}

interface Options extends RequestConfig<any> {
  opts: {
    handoffPurpose?: boolean;
    ignorableAuthBlocks: AuthorizationBlocks[];
  };
}

export function brandingFetcherBehaviorSubjectFactory({
  webapiFetcher
}: {
  webapiFetcher: BehaviorSubjectSubscribe<Fetcher>;
}): BehaviorSubject<BrandingFetcherInterface | undefined> {
  const [dispatch, addListener] = createBehaviorSubject<
    BrandingFetcherInterface | undefined
  >(undefined);
  let brandingUnsubscribe = (): void => void 0;

  webapiFetcher((fetcher) => {
    const options: Options = {
      method: 'GET',
      url: '/v1/branding',
      opts: {
        ignorableAuthBlocks: [
          AuthorizationBlocks.ACTIVATE_ACCOUNT,
          AuthorizationBlocks.OOBA_REQUIRED
        ]
      }
    };
    if (isLimitedUseAccessToken()) {
      options.opts.handoffPurpose = true;
    }
    const [makeRequest, onRequestUpdate] = fetcher(options);
    brandingUnsubscribe();
    makeRequest();
    brandingUnsubscribe = onRequestUpdate(
      ([, response, brandingData, error]) => {
        // Retry the cache and bust the cache.
        // TODO: infinite retry here... but we do not have a good pattern
        // for handling issues here. Exponential back-off should occur in the
        // fetcher itself as an extra decorator probably
        if (error || response?.ok === false) {
          makeRequest(true);
        }
        if (response?.ok) {
          dispatch(brandingData);
        }
      }
    );
  });

  return [dispatch, addListener];
}
